var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", { attrs: { prop: "name", label: "Ho ten" } }),
          _vm._l(_vm.tableHeader, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: { prop: item.key, label: item.value },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom-start",
                              width: "200",
                              trigger: "hover",
                              content: item.value,
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v("Khoản " + _vm._s(index + 1))]
                            ),
                          ]
                        ),
                        _c("br"),
                        _c("el-checkbox", {
                          on: {
                            change: function ($event) {
                              return _vm.checkHeaderMethod(
                                scope.$index,
                                item.key,
                                item.check
                              )
                            },
                          },
                          model: {
                            value: item.check,
                            callback: function ($$v) {
                              _vm.$set(item, "check", $$v)
                            },
                            expression: "item.check",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.conmeo.filter((x) => x.id == item.key)
                          .length == 1
                          ? _c("el-checkbox", {
                              model: {
                                value: scope.row.conmeo.filter(
                                  (x) => x.id == item.key
                                )[0].mang,
                                callback: function ($$v) {
                                  _vm.$set(
                                    scope.row.conmeo.filter(
                                      (x) => x.id == item.key
                                    )[0],
                                    "mang",
                                    $$v
                                  )
                                },
                                expression:
                                  "scope.row.conmeo.filter((x) => x.id == item.key)[0].mang",
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.CheckB()
            },
          },
        },
        [_vm._v("click B")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }